<template>
    <!-- 403 сторінка -->
    <div class="wrapper">
        <div class="block">
        <h1>
            <b>
                {{ this.$t('403_title') }}
            </b>
        </h1>
        <h2>{{ this.$t('403_desc') }}</h2>
        </div>
    </div>
</template>

<style scoped>
.wrapper{
    display: grid;
    align-items: center;
    justify-content: center;
}
.block{
    text-align: center;
}
</style>